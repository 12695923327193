import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/phonica-02.png";

const IndexPage = () => (
    <Layout>
        <SEO title="Home"/>

        <div className={"page-body home"}>
            <div className={"container"}>
                <div className={"row justify-content-center"}>
                    <div className={"col-4"}>
                        <h1>Improve speech with AI.</h1>
                        <div className={"get-started"}>
                            <a href="/about">Learn More</a>
                        </div>
                    </div>
                    <div className={"col-8"}>
                        <img alt={"Dashboard"} class="img-responsive fit-image" src={featureImage}/>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
)

export default IndexPage
